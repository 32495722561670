import { AbilityCan, AbilitySubjects } from '@common/enums';
import { canUseChatGptForLandfill } from '@common/functions';
import { replaceRouteParams, routes } from '@common/routes';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo } from 'react';
import { matchPath, Redirect, useHistory } from 'react-router';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { PageNavMenu } from '~/@sochi-components/PageNavMenu';
import { landfillDetailRoute } from '~/@store/routes';
import { TopBar } from '~/@views/UserView/UserViewLayout/TopBar';
import { useSettings, useUserAbilities } from '~/contexts';
import { LandfillQuery, LandfillQuery_landfill, LandfillQueryVariables } from '~/graphql';
import type { WithGraphqlProps } from '~/services/hoc';
import { handledGraphql } from '~/services/hoc';
import { handleLandfillGraphqlPermissionError } from '~/utils/landfill';

import * as landfillQueries from '../../../@store/landfills/landfills.queries';
import { LandfillAiTab } from './LandfillAiTab';
import { LandfillDeviationsTab } from './LandfillDeviationsTab';
import { LandfillDocumentation } from './LandfillDocumentation';
import { LandfillFollowUp } from './LandfillFollowUp';
import { LandfillInfo } from './LandfillInfo';
import { LandfillMaterials } from './LandfillMaterials';
import { LandfillOverview } from './LandfillOverview';
import { LandfillSchedule } from './LandfillSchedule';

export type LandfillDetailsDataProps = {
    landfill: LandfillQuery_landfill;
    refetch?: () => Promise<unknown>;
};

type MatchParams = {
    landfillId: string | undefined;
};

type ExternalProps = RouteComponentProps<MatchParams>;

type RouteData = {
    url: string;
    component: React.ComponentType<LandfillDetailsDataProps>;
};

type InjectedProps = WithGraphqlProps<ExternalProps, LandfillQuery, LandfillQueryVariables>;

const routeItems: RouteData[] = [
    { url: routes.sochi.landfillOverview, component: LandfillOverview },
    { url: routes.sochi.landfillInfo, component: LandfillInfo },
    { url: routes.sochi.landfillDeviations, component: LandfillDeviationsTab },
    { url: routes.sochi.landfillMaterials, component: LandfillMaterials },
    { url: routes.sochi.landfillSchedule, component: LandfillSchedule },
    { url: routes.sochi.landfillDocumentation, component: LandfillDocumentation },
    { url: routes.sochi.landfillAi, component: LandfillAiTab },
    { url: routes.sochi.landfillDeliveries, component: LandfillFollowUp },
    { url: routes.sochi.landfillDeliveriesForMass, component: LandfillFollowUp },
];

const menuItems = [
    {
        title: <LocalizableText code={'overview'} />,
        url: routes.sochi.landfillOverview,
    },
    {
        title: <LocalizableText code={'materials'} />,
        url: routes.sochi.landfillMaterials,
    },
    {
        title: <LocalizableText code={'documentations'} />,
        url: routes.sochi.landfillDocumentation,
    },
    {
        title: <LocalizableText code={'transportSchedule'} />,
        url: routes.sochi.landfillSchedule,
    },
    {
        title: <LocalizableText code={'landfillDeliveries'} />,
        url: routes.sochi.landfillDeliveries,
    },
    {
        title: <LocalizableText code={'deviations'} />,
        url: routes.sochi.landfillDeviations,
    },
    {
        title: <LocalizableText code={'landfillInfo'} />,
        url: routes.sochi.landfillInfo,
    },
];

const LandfillPage = ({ history, match, data }: InjectedProps) => {
    const { className } = useFunctionalBem(LandfillPage);
    const abilityContext = useUserAbilities();
    const { ability } = abilityContext;

    const landfillId = useMemo(() => match.params.landfillId || '', [match]);

    const {
        settings: { chatGptEnabled },
    } = useSettings();

    const { location } = useHistory();

    const onBack = () => {
        const isMassDetail = !!matchPath(location.pathname, {
            path: replaceRouteParams(routes.sochi.landfillDeliveriesForMass, {
                landfillId,
            }),
        });

        if (isMassDetail) {
            history.goBack();
        } else {
            history.push('/');
        }
    };

    const preparedMenuItems = useMemo(() => {
        const items = menuItems.map(item => ({
            ...item,
            url: replaceRouteParams(item.url, { landfillId }),
        }));

        if (chatGptEnabled && data.landfill && canUseChatGptForLandfill(data.landfill))
            items.push({
                title: <LocalizableText code={'ChatGpt.tabTitle'} />,
                url: replaceRouteParams(routes.sochi.landfillAi, { landfillId }),
            });

        if (ability.can(AbilityCan.READ, AbilitySubjects.ADMIN_PAGES)) {
            items.unshift({
                title: <LocalizableText code={'admin20'} />,
                url: landfillDetailRoute(landfillId),
            });
        }

        return items;
    }, [data.landfill, landfillId, chatGptEnabled, ability]);

    const routeRender = useCallback(
        (route: RouteData) => {
            const CompClass = route.component;

            return () => <CompClass landfill={data.landfill!} refetch={data.refetch} />;
        },
        [data]
    );

    if (isEmpty(data.landfill)) {
        return null;
    }

    return (
        <>
            <TopBar />
            <div className={className}>
                <PageNavMenu
                    items={preparedMenuItems}
                    onBack={onBack}
                    title={data.landfill?.name}
                    titleLabel={<LocalizableText code={'topBar.landfill'} />}
                />
                <Switch key={landfillId}>
                    {routeItems.map(route => (
                        <Route key={route.url} path={route.url} render={routeRender(route)} exact />
                    ))}
                    <Redirect from="/" to={replaceRouteParams(routes.sochi.landfillInfo, { landfillId })} />
                </Switch>
            </div>
        </>
    );
};

const withData = handledGraphql<LandfillQuery, ExternalProps, LandfillQueryVariables, InjectedProps>(
    landfillQueries.LandfillQuery,
    {
        options: props => {
            return {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
                variables: {
                    id: props.match.params.landfillId || '',
                },
            };
        },
    },
    handleLandfillGraphqlPermissionError
);

export default withData(LandfillPage);
