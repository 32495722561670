import { LandfillQuery } from '~/@store/landfills/landfills.queries';
import { ProjectQuery } from '~/@store/projects/projects.queries';
import client from '~/apolloClient';
import {
    UpdateDeliveryLineReceiverFieldsMutation,
    UpdateDeliveryLineReceiverFieldsMutationVariables,
    UpdateDeliveryLineSenderCommentMutation,
    UpdateDeliveryLineSenderCommentMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from './receipts.queries';

export const updateDeliveryLineSenderComment = (projectId: string, lineId: string, senderComment: string) =>
    client
        .mutate<UpdateDeliveryLineSenderCommentMutation, UpdateDeliveryLineSenderCommentMutationVariables>({
            mutation: queries.UpdateDeliveryLineSenderCommentMutation,
            variables: { projectId, lineId, senderComment },
            refetchQueries: [
                {
                    query: ProjectQuery,
                    variables: { id: projectId },
                },
            ],
        })
        .catch(handleApolloError);

export const updateDeliveryLineReceiverComment = (landfillId: string, lineId: string, receiverComment: string) =>
    client
        .mutate<UpdateDeliveryLineReceiverFieldsMutation, UpdateDeliveryLineReceiverFieldsMutationVariables>({
            mutation: queries.UpdateDeliveryLineReceiverFieldsMutation,
            variables: { input: { id: lineId, receiverComment } },
            refetchQueries: [
                {
                    query: LandfillQuery,
                    variables: { id: landfillId },
                },
            ],
        })
        .catch(handleApolloError);

export const updateDeliveryLineReceiverCheck = (landfillId: string, lineId: string, receiverCheck: boolean) =>
    client
        .mutate<UpdateDeliveryLineReceiverFieldsMutation, UpdateDeliveryLineReceiverFieldsMutationVariables>({
            mutation: queries.UpdateDeliveryLineReceiverFieldsMutation,
            variables: { input: { id: lineId, receiverCheck } },
            refetchQueries: [
                {
                    query: LandfillQuery,
                    variables: { id: landfillId },
                },
            ],
        })
        .catch(handleApolloError);
