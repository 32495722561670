import './LandfillWeightReceipts.scss';

import { DocumentTypes } from '@common/enums';
import { parseDateFrom } from '@common/utils';
import { IconButton } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { ContaminationLevelFormatter, NullableFormatter, TonnsFormatter } from '~/@components/Formatters';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { DisplayItemCountSelect } from '~/@sochi-components/DisplayItemCountSelect';
import { FilePreview } from '~/@sochi-components/FilePreview';
import { SearchIcon } from '~/@sochi-components/Icons';
import { ISochiTableColumn, SochiTable, SochiTableCellNoPadding } from '~/@sochi-components/SochiTable';
import { SochiHeadCellAlignment } from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { TransportReceiptDialog } from '~/@sochi-components/TransportReceiptDialog';
import { useDetachDeliveryEvent } from '~/@user-store/landfillDeliveryEvents/useDetachDeliveryEvent';
import { BulkDownloadStore } from '~/@user-store/weightReceipts/bulkDownloadStore';
import {
    LandfillWeightReceiptFilterField,
    LandfillWeightReceiptInfo,
    landfillWeightReceiptsStore,
} from '~/@user-store/weightReceipts/landfillWeightReceipts.store';
import { BulkDownloadDialog } from '~/@views/UserView/common/BulkDownloadDialog/BulkDownloadDialog';
import { LoadPercentageCell } from '~/@views/UserView/common/LoadPercentageCell';
import { ReceiverCommentField } from '~/@views/UserView/LandfillPage/LandfillFollowUp/LandfillWeightReceipts/ReceiverCommentField';
import { DeliveryLineTimesField } from '~/@views/UserView/ProjectPage/common/DeliveriesTable/DeliveryLineTimesField';
import { getContentUrl } from '~/apolloClient';
import { useUserAbilities } from '~/contexts';
import type { LandfillQuery_landfill_ordersInfo } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog, showCustomDialog } from '~/services/dialog';
import { handleLoadingPromise } from '~/services/loader';
import { canDetachEventFromLine, canUpdateDeliveryLineReceiverFields } from '~/utils/auth';
import { formatDate } from '~/utils/date';
import { downloadDeliveryPhotos, downloadLandfillReceipts, downloadLandfillReceiptsXlsx } from '~/utils/landfill';

import type { LandfillDetailsDataProps } from '../../LandfillPage';
import { LandfillWeightReceiptTableHeadCell } from './LandfillWeightReceiptTableHeadCell';
import { ReceiverCheckField } from './ReceiverCheckField';

type IOrderInfo = LandfillQuery_landfill_ordersInfo;

const getLinesInfo = (landfill: LandfillDetailsDataProps['landfill']): LandfillWeightReceiptInfo[] => {
    const ordersMap = landfill.ordersInfo.reduce(
        (map: Map<string, IOrderInfo>, order: IOrderInfo) => map.set(order.orderNumber, order),
        new Map()
    );

    const lines: LandfillWeightReceiptInfo[] = [];
    for (const line of landfill.deliveryLinesInfo || []) {
        const order = ordersMap.get(line.orderNumber);
        if (order) {
            lines.push({
                id: line.id,
                serialNumber: order.dumpLoad.serialNumber,
                projectName: order.projectName,
                massCategory: order.dumpLoad.dumpType.name,
                solidTestResult: order.dumpLoad.solidTestResult,
                comment: order.dumpLoad.comment,
                receiverComment: line.receiverComment,
                truckRegistrationNumber: line.truckRegistrationNumber,
                truckCapacity: line.truckCapacity,
                plannedStartDate: line.plannedStartDate,
                weight: line.weight,
                excavationDate: line.excavationDate,
                startDeliveryDate: line.startDeliveryDate,
                endDeliveryDate: line.endDeliveryDate,
                receiverCheck: line.receiverCheck,
                receiptUrl: line.receiptUrl,
                photoUrl: line.deliveryEvent?.photoUrl || null,
            });
        }
    }

    return lines;
};

type AdditionalProps = {
    serialNumber?: string;
};

export const LandfillWeightReceipts: FC<LandfillDetailsDataProps & AdditionalProps> = ({ landfill, serialNumber }) => {
    const { element, className } = useFunctionalBem(LandfillWeightReceipts);

    useEffect(() => {
        landfillWeightReceiptsStore.setList(getLinesInfo(landfill), serialNumber);
    }, [landfill, serialNumber]);

    useEffect(() => {
        return () => landfillWeightReceiptsStore.clear();
    }, []);

    const filteredLines: LandfillWeightReceiptInfo[] = useObservable(() => landfillWeightReceiptsStore.filteredList);
    const filteredLinesWithPhoto = useMemo(() => filteredLines.filter(l => !!l.photoUrl), [filteredLines]);
    const lines: LandfillWeightReceiptInfo[] = useObservable(() => landfillWeightReceiptsStore.itemsToDisplay);
    const isAnyFilterSet: boolean = useObservable(() => landfillWeightReceiptsStore.isAnyFilterSet);

    const onDownloadReceiptsZip = useCallback(() => {
        const ids = filteredLines.map(l => l.id);
        const downLoadFunc = (ids: string[], index: number) =>
            downloadLandfillReceipts(landfill.id, `${landfill.name}-${i18n.receipts}-page-${index + 1}`, ids);

        const store = new BulkDownloadStore(downLoadFunc, ids);

        const ignoredPromise = showCustomDialog({
            title: i18n.receipts,
            render: closeDialog => <BulkDownloadDialog store={store} onClose={closeDialog} />,
        });
    }, [landfill, filteredLines]);

    const onDownloadPhotos = useCallback(() => {
        const ids = filteredLinesWithPhoto.map(l => l.id);
        const downLoadFunc = (ids: string[], index: number) =>
            downloadDeliveryPhotos(
                landfill.id,
                `${landfill.name}-${i18n.ProjectDocuments.weightReceiptTable.photos}-page-${index + 1}`,
                ids
            );

        const store = new BulkDownloadStore(downLoadFunc, ids);

        const ignoredPromise = showCustomDialog({
            title: i18n.receipts,
            render: closeDialog => <BulkDownloadDialog store={store} onClose={closeDialog} />,
        });
    }, [landfill, filteredLinesWithPhoto]);

    const onDownloadReceiptsXlsx = useCallback(() => {
        const ignoredPromise = downloadLandfillReceiptsXlsx(
            landfill.id,
            landfill.name,
            filteredLines.map(l => l.id)
        );
    }, [landfill, filteredLines]);

    const showReceipt = useCallback(transportedData => {
        const ignoredPromise = showCustomDialog({
            forceCrossClose: true,
            render: closeDialog => (
                <TransportReceiptDialog
                    closeDialog={closeDialog}
                    transportedData={transportedData}
                    documentType={DocumentTypes.LANDFILL}
                />
            ),
        });
    }, []);

    const [detach, ignored] = useDetachDeliveryEvent(landfill.id);

    const onDetach = useCallback(
        async (deliveryLine: LandfillWeightReceiptInfo) => {
            const confirmed = await showConfirmDialog({
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.detach'} />,
                message: i18n.ProjectDocuments.weightReceiptTable.confirmDetach,
            });

            if (!confirmed) return;

            return handleLoadingPromise(detach(deliveryLine.id));
        },
        [detach]
    );

    const ability = useUserAbilities();

    const tableColumns: Array<ISochiTableColumn<LandfillWeightReceiptInfo>> = useMemo(() => {
        const columns: Array<ISochiTableColumn<LandfillWeightReceiptInfo>> = [
            {
                render: (data: LandfillWeightReceiptInfo) => <NullableFormatter>{data.serialNumber}</NullableFormatter>,
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.declarationNumber'} />}
                        fieldName={LandfillWeightReceiptFilterField.serialNumber}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
            },
            {
                render: (data: LandfillWeightReceiptInfo) => data.massCategory,
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.mass'} />}
                        fieldName={LandfillWeightReceiptFilterField.massCategory}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                alignLeft: true,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => (
                    <ContaminationLevelFormatter>{data.solidTestResult}</ContaminationLevelFormatter>
                ),
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.level'} />}
                        fieldName={LandfillWeightReceiptFilterField.solidTestResult}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                alignLeft: true,
            },
            {
                render: (data: LandfillWeightReceiptInfo) =>
                    data.comment || <NullableFormatter>{data.comment}</NullableFormatter>,
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.comment'} />}
                        fieldName={LandfillWeightReceiptFilterField.comment}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                cellClassName: element('comment-column'),
            },
            {
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.project'} />}
                        fieldName={LandfillWeightReceiptFilterField.projectName}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: (data: LandfillWeightReceiptInfo) => <NullableFormatter>{data.projectName}</NullableFormatter>,
                alignLeft: true,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => <DeliveryLineTimesField line={data} />,
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.timestamp'} />,
                cellComponent: SochiTableCellNoPadding,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => formatDate(parseDateFrom(data.plannedStartDate)),
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.date'} />}
                        fieldName={LandfillWeightReceiptFilterField.plannedStartDate}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                alignLeft: true,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => data.truckRegistrationNumber,
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.registrationNumber'} />}
                        fieldName={LandfillWeightReceiptFilterField.truckRegistrationNumber}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                alignLeft: true,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => <TonnsFormatter>{data.truckCapacity}</TonnsFormatter>,
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.truckCapacity'} />,
            },

            {
                render: (data: LandfillWeightReceiptInfo) => <TonnsFormatter>{data.weight}</TonnsFormatter>,
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.weight'} />}
                        fieldName={LandfillWeightReceiptFilterField.weight}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: LandfillWeightReceiptInfo) => <LoadPercentageCell line={data} />,
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.loadPercentage'} />,
            },
            {
                render: (data: LandfillWeightReceiptInfo) =>
                    data.photoUrl ? (
                        <FilePreview
                            url={getContentUrl(data.photoUrl)}
                            filename={data.photoUrl}
                            label={i18n.ProjectDocuments.weightReceiptTable.photo}
                        />
                    ) : null,
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.photo'} />,
            },
            {
                render: (data: LandfillWeightReceiptInfo) =>
                    data.photoUrl ? (
                        <IconButton
                            color="primary"
                            onClick={() => onDetach(data)}
                            disabled={!canDetachEventFromLine(ability, landfill)}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    ) : null,
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.detach'} />,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => (
                    <span
                        className={element('view', { disabled: !data.receiptUrl })}
                        onClick={() => (data.receiptUrl ? showReceipt(data) : undefined)}>
                        <SearchIcon color={data.receiptUrl ? 'primary' : 'disabled'} fontSize="inherit" />
                        <LocalizableText code={'view'} />
                    </span>
                ),
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.scaleReceipt'} />,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => (
                    <ReceiverCheckField
                        line={data}
                        landfillId={landfill.id}
                        disabled={!canUpdateDeliveryLineReceiverFields(ability, landfill)}
                    />
                ),
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.check'} />,
            },
            {
                render: (data: LandfillWeightReceiptInfo) => (
                    <ReceiverCommentField
                        line={data}
                        landfillId={landfill.id}
                        disabled={!canUpdateDeliveryLineReceiverFields(ability, landfill)}
                    />
                ),
                title: (
                    <LandfillWeightReceiptTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.notes'} />}
                        fieldName={LandfillWeightReceiptFilterField.receiverComment}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                cellClassName: element('notes'),
            },
        ];

        return columns;
    }, [landfill, ability, showReceipt, onDetach, element]);

    return (
        <div className={className}>
            <SochiTitle title={''}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => landfillWeightReceiptsStore.filter.clearValues()}
                    disabled={!isAnyFilterSet}>
                    {i18n.clear}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={filteredLines.length === 0}
                    onClick={onDownloadReceiptsXlsx}>
                    <LocalizableText code={'ProjectDocuments.buttons.exportToXlsx'} />
                    {filteredLines.length ? <>&nbsp;&nbsp;({filteredLines.length})</> : null}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={filteredLines.length === 0}
                    onClick={onDownloadReceiptsZip}>
                    <LocalizableText
                        code={
                            isAnyFilterSet
                                ? 'ProjectDocuments.buttons.downloadSelected'
                                : 'ProjectDocuments.buttons.downloadAll'
                        }
                    />
                    {filteredLines.length ? <>&nbsp;&nbsp;({filteredLines.length})</> : null}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={filteredLinesWithPhoto.length === 0}
                    onClick={onDownloadPhotos}>
                    <LocalizableText
                        code={
                            isAnyFilterSet
                                ? 'ProjectDocuments.buttons.downloadSelectedPhotos'
                                : 'ProjectDocuments.buttons.downloadAllPhotos'
                        }
                    />
                    {filteredLines.length ? <>&nbsp;&nbsp;({filteredLinesWithPhoto.length})</> : null}
                </Button>
                <DisplayItemCountSelect store={landfillWeightReceiptsStore} />
            </SochiTitle>
            <SochiTable
                columns={tableColumns}
                items={lines}
                keyGetter={({ id }) => id}
                emptyListMessage={
                    isAnyFilterSet ? (
                        i18n.noDataForFilter
                    ) : (
                        <LocalizableText code={'ProjectDocuments.noDocumentationFound'} />
                    )
                }
                stickyFirstColumn
                className={element('table')}
            />
        </div>
    );
};
