import type { NormalizedCacheObject, ServerError } from '@apollo/client';
import {
    ApolloClient,
    ApolloLink,
    defaultDataIdFromObject,
    from,
    HttpLink,
    InMemoryCache,
    split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { VERSION_HEADER } from '@common/constants';
import { apiEndpoints, fullRouteWith, replaceRouteParams, routes } from '@common/routes';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';
import cookie from 'js-cookie';
import { get, isPlainObject, values } from 'lodash';

import { FrontApiError } from '~/utils/error';

import browserHistory from './browserHistory';
import config from './config';
import { ErrorCodes, HttpCodes } from './config/enum';
import i18n from './i18n';
import { getAuthToken, removeAuthToken } from './utils/auth';

const developmentIp = cookie.get('SERVER_IP') || window.location.hostname || 'localhost';
const apiPrefix = '/api';

export const baseApiUrl = process.env.NODE_ENV === 'development' ? `http://${developmentIp}:4080` : apiPrefix;

const getApiUri = (uri: string) => fullRouteWith(baseApiUrl, uri);

//#region REST API

export const generateFakeFilesUri = getApiUri(apiEndpoints.databaseGenerateFakeFiles);

export const publicSettingsUri = getApiUri(apiEndpoints.publicSettings);

export const loginUri = getApiUri(apiEndpoints.login);

export const signupUri = getApiUri(apiEndpoints.signup);

export const forgotUri = getApiUri(apiEndpoints.forgot);

export const confirmEmailUri = getApiUri(apiEndpoints.confirm);

export const projectAnalysisUri = getApiUri(apiEndpoints.projectAnalysis);

export const uploadDeclarationUri = getApiUri(apiEndpoints.declarationTempl);

export const downloadReceiptUrl = getApiUri(apiEndpoints.deliveryLineReceipt);

export const downloadPdfReceiptUrl = getApiUri(apiEndpoints.deliveryLineReceiptPdf);

export const downloadProjectReceiptsUrl = getApiUri(apiEndpoints.projectDeliveryReceipts);

export const downloadProjectReceiptsXlsxUrl = getApiUri(apiEndpoints.projectReceiptsXlsx);

export const downloadLandfillReceiptsXlsxUrl = getApiUri(apiEndpoints.landfillReceiptsXlsx);

export const downloadLandfillReceiptsUrl = getApiUri(apiEndpoints.landfillDeliveryReceipts);

export const landfillEventPhotosUrl = getApiUri(apiEndpoints.landfillEventPhotos);

export const downloadCertificateUri = getApiUri(apiEndpoints.cert);

export const landfillCertificateUri = getApiUri(apiEndpoints.landfillCertificates);

export const landfillDeclarationsUri = getApiUri(apiEndpoints.landfillDeclarations);

export const exportDeliveryLinesUrl = getApiUri(apiEndpoints.orderExportLines);

export const projectDeviationPhotoUrl = getApiUri(apiEndpoints.projectDeviations);

export const landfillDeviationPhotoUrl = getApiUri(apiEndpoints.landfillDeviations);

export const getContentUrl = (fileName: string) =>
    replaceRouteParams(getApiUri(apiEndpoints.content), { filename: fileName });

//#endregion

//#region GraphQL API

export const uri = getApiUri('/graphql');
console.log('Using API:' + uri); // eslint-disable-line

const httpLink = new HttpLink({ uri });

//https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
const uploadLink = createUploadLink({ uri }) as unknown as ApolloLink;

const MiddlewareLink = new ApolloLink((operation, forward) => {
    const token = getAuthToken();

    operation.setContext({
        headers: {
            authorization: token ? `JWT ${token}` : null,
            [VERSION_HEADER]: window.__version,
        },
    });

    return forward(operation);
});

const LoggerLink = new ApolloLink((operation, forward) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.log(`[GraphQL Logger] ${operation.operationName}`);
    }

    return forward(operation).map(result => {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line
            console.log(`[GraphQL Logger] received result from ${operation.operationName}`);
        }

        return result;
    });
});

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, code }: GraphQLError & { code?: ErrorCodes }) => {
            // eslint-disable-next-line no-console
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

            switch (code) {
                case ErrorCodes.INVALID_ID:
                    return browserHistory.replace(routes.notFound);

                default:
                    return null;
            }
        });

        return;
    }

    const apiError =
        networkError?.name === 'ServerError' ? FrontApiError.fromDto((networkError as ServerError).result) : null;

    switch (apiError?.code) {
        case ErrorCodes.NOT_AUTHORIZED:
        case ErrorCodes.PERMISSION_ERROR:
            removeAuthToken();

            return browserHistory.replace(routes.login);
        case ErrorCodes.USER_BLOCKED:
            removeAuthToken();

            return browserHistory.replace(routes.userBlocked);
        case ErrorCodes.USER_NOT_VERIFIED:
            removeAuthToken();

            return browserHistory.replace(routes.notApproved);
        case ErrorCodes.USER_NOT_CONFIRMED:
            removeAuthToken();

            return browserHistory.replace(routes.notConfirmedEmail);
    }

    const statusCode: number | null = get(networkError, 'statusCode', null);

    switch (statusCode) {
        case HttpCodes.NOT_FOUND:
            return browserHistory.replace(routes.notFound);

        case HttpCodes.NOT_ACCEPTABLE:
            alert(i18n.errorCodes.WRONG_VERSION);

            return;

        default:
            // eslint-disable-next-line no-console
            console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    }
});

const isFile = (value: unknown): boolean => {
    if (Array.isArray(value) || isPlainObject(value)) return values(value).map(isFile).includes(true);

    const file = typeof File !== 'undefined' && value instanceof File;
    const blob = typeof Blob !== 'undefined' && value instanceof Blob;

    return file || blob;
};

const isUpload = ({ variables }: { variables: Record<PropertyKey, unknown> }) => Object.values(variables).some(isFile);

const requestLink = split(isUpload, uploadLink, httpLink);
const link = from([MiddlewareLink, LoggerLink, ErrorLink, requestLink]);

const cache = new InMemoryCache({
    // NOTE: Deprecated, please check the alternative solution for AC3 below
    // TODO: Remove the following code if the alternative is OK
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#dataidfromobject
    dataIdFromObject: object => {
        // workaround buggy apollo cache, dont cache certain types at all!
        // see: https://github.com/apollographql/apollo-client/issues/3234#issuecomment-405917223
        switch (object.__typename) {
            case 'LandfillSubarea':
                return `${Math.random()}`;
            default:
                return defaultDataIdFromObject(object); // fall back to default handling
        }
    },
    // REVIEW: Alternative solution
    // typePolicies: {
    //     LandfillResult: {
    //         keyFields: (/* object, context */) => {
    //             return false;
    //         },
    //     },
    //     LandfillSubarea: {
    //         keyFields: (/* object, context */) => {
    //             return false;
    //         },
    //     },
    // },
});

const client = new ApolloClient<NormalizedCacheObject>({
    link,
    cache,
    connectToDevTools: config.isDev,
});

export default client;

//#endregion
