import { gql } from '@apollo/client';

export const UpdateDeliveryLineSenderCommentMutation = gql`
    mutation UpdateDeliveryLineSenderCommentMutation($projectId: String!, $lineId: String!, $senderComment: String!) {
        deliveryLineUpdateSenderComment(projectId: $projectId, lineId: $lineId, senderComment: $senderComment) {
            id
            senderComment
        }
    }
`;

export const UpdateDeliveryLineReceiverFieldsMutation = gql`
    mutation UpdateDeliveryLineReceiverFieldsMutation($input: DeliveryLineReceiverUpdateInput!) {
        deliveryLineUpdateReceiverFields(input: $input) {
            id
            receiverComment
            receiverCheck
        }
    }
`;
