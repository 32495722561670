import { ContaminationLevel } from '@common/enums';

type ColorSchema = {
    backgroundColor: string;
    borderColor: string;
};

export const ToxicLevelColorSchemas: Record<ContaminationLevel, ColorSchema> = {
    [ContaminationLevel.mrr]: {
        backgroundColor: 'rgba(73, 255, 87, 0.3)',
        borderColor: '#3ee45c',
    },
    [ContaminationLevel.km]: {
        backgroundColor: 'rgba(252, 255, 0, 0.3)',
        borderColor: '#e2e600',
    },
    [ContaminationLevel.mkm]: {
        backgroundColor: 'rgba(87, 127, 189, 0.3)',
        borderColor: '#577fbd',
    },
    [ContaminationLevel.inert]: {
        backgroundColor: 'rgba(67, 255, 156, 0.3)',
        borderColor: '#26e49f',
    },
    [ContaminationLevel.ifa]: {
        backgroundColor: 'rgba(241, 152, 71, 0.3)',
        borderColor: '#f19847',
    },
    [ContaminationLevel.fa]: {
        backgroundColor: 'rgba(247, 25, 18, 0.3)',
        borderColor: '#e81811',
    },
    [ContaminationLevel.limit]: {
        backgroundColor: 'rgba(247, 25, 18, 0.3)',
        borderColor: '#e81811',
    },
    [ContaminationLevel.na]: {
        backgroundColor: 'rgba(247, 25, 18, 0.3)',
        borderColor: '#e81811',
    },
};
