import { ContaminationLevel } from '@common/enums';

export const ToxicLimitsSolidColors: Partial<Record<ContaminationLevel, string | undefined>> = {
    [ContaminationLevel.mrr]: '#FFFFFF',
    [ContaminationLevel.km]: '#FEF8E8',
    [ContaminationLevel.mkm]: '#ECF0FF',
    [ContaminationLevel.ifa]: '#FFE2C9',
    [ContaminationLevel.fa]: '#FDEBEB',
    [ContaminationLevel.limit]: '#FDEBEB',
};

export const ToxicLimitsLeachingColors: Partial<Record<ContaminationLevel, string>> = {
    [ContaminationLevel.inert]: '#FDEBEB',
    [ContaminationLevel.ifa]: '#FDEBEB',
    [ContaminationLevel.limit]: '#FDEBEB',
};
