import { ApolloError } from '@apollo/client';
import { IAbilityUser } from '@common/abilities/abilities.types';
import { getDefaultWorkTime } from '@common/constants';
import { ErrorCodes } from '@common/enums';
import axios from 'axios';

import { IDetailedLandfill } from '~/@store/landfills';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { ChartMaterialCategory, IChartMaterialCategory } from '~/config/enum';
import type { IAbilityContext } from '~/contexts';
import type {
    LandfillCreateMutation,
    LandfillCreateMutation_landfillCreate,
    LandfillCreateMutationVariables,
    LandfillInput,
    LandfillQuery_landfill,
    LandfillRemoveCertificateMutation,
    LandfillRemoveCertificateMutationVariables,
    LandfillSubareaCreateMutation,
    LandfillSubareaCreateMutationVariables,
    LandfillSubareaDeleteMutation,
    LandfillSubareaDeleteMutationVariables,
    LandfillSubareaInput,
    LandfillSubareaUpdateMutation,
    LandfillSubareaUpdateMutationVariables,
    LandfillUpdateMutation,
    LandfillUpdateMutation_landfillUpdate,
    LandfillUpdateMutationVariables,
} from '~/graphql';
import { LandfillDetailedQuery_landfill_location, LandfillDetailedQuery_landfill_workTime } from '~/graphql';
import { handleLoadingPromise } from '~/services/loader';
import { saveToClient } from '~/utils/files';

import * as queries from '../@store/landfills/landfills.queries';
import client, {
    downloadLandfillReceiptsUrl,
    downloadLandfillReceiptsXlsxUrl,
    landfillEventPhotosUrl,
} from '../apolloClient';
import i18n from '../i18n';
import { canCreateLandfill, canUpdateLandfill } from './auth';
import { getAxiosConfig, handleAxiosError } from './axios';
import { handleApolloError, handleEntityGraphqlPermissionError } from './index';

export type ILandfillInput = LandfillInput;

export const createLandfill = (
    user: IAbilityUser,
    context: IAbilityContext,
    input: ILandfillInput
): Promise<LandfillCreateMutation_landfillCreate> => {
    if (!canCreateLandfill(user, context)) return Promise.reject();

    return handleLoadingPromise(
        client.mutate<LandfillCreateMutation, LandfillCreateMutationVariables>({
            mutation: queries.LandfillCreateMutation,
            variables: {
                input,
            },
        })
    )
        .catch(handleApolloError)
        .then(res => {
            const landfill = res.data?.landfillCreate;
            if (!landfill) return Promise.reject();

            return landfill;
        });
};

export const updateLandfill = (
    user: IAbilityUser,
    context: IAbilityContext,
    landfill: LandfillQuery_landfill,
    input: ILandfillInput
): Promise<LandfillUpdateMutation_landfillUpdate> => {
    if (!canUpdateLandfill(user, context, landfill)) return Promise.reject();

    return handleLoadingPromise(
        client.mutate<LandfillUpdateMutation, LandfillUpdateMutationVariables>({
            mutation: queries.LandfillUpdateMutation,
            variables: {
                input,
            },
        })
    )
        .catch(handleApolloError)
        .then(res => {
            const landfill = res.data?.landfillUpdate;
            if (!landfill) return Promise.reject();

            return landfill;
        });
};

export const removeLandfillCertificate = (
    user: IAbilityUser,
    context: IAbilityContext,
    landfill: LandfillQuery_landfill,
    certId: string
) => {
    if (!canUpdateLandfill(user, context, landfill)) return Promise.reject();

    return handleLoadingPromise(
        client.mutate<LandfillRemoveCertificateMutation, LandfillRemoveCertificateMutationVariables>({
            mutation: queries.LandfillRemoveCertificateMutation,
            variables: {
                landfillId: landfill.id,
                certId,
            },
            refetchQueries: [
                {
                    query: queries.LandfillQuery,
                    variables: {
                        id: landfill.id,
                    },
                },
            ],
            awaitRefetchQueries: true,
        })
    ).catch(handleApolloError);
};

export type ILandfillSubareaInput = LandfillSubareaInput;

export const createSubarea = async (landfill: { id: string }, input: ILandfillSubareaInput) => {
    return handleLoadingPromise(
        client.mutate<LandfillSubareaCreateMutation, LandfillSubareaCreateMutationVariables>({
            mutation: queries.LandfillSubareaCreateMutation,
            variables: {
                landfillId: landfill.id,
                input,
            },
            refetchQueries: [
                {
                    query: queries.LandfillQuery,
                    variables: {
                        id: landfill.id,
                    },
                },
                {
                    query: queries.AdminLandfillQuery,
                    variables: {
                        id: landfill.id,
                    },
                },
            ],
        })
    );
};

export const updateSubarea = async (landfill: { id: string }, input: ILandfillSubareaInput) => {
    return handleLoadingPromise(
        client
            .mutate<LandfillSubareaUpdateMutation, LandfillSubareaUpdateMutationVariables>({
                mutation: queries.LandfillSubareaUpdateMutation,
                variables: {
                    landfillId: landfill.id,
                    input,
                },
                refetchQueries: [
                    {
                        query: queries.LandfillQuery,
                        variables: {
                            id: landfill.id,
                        },
                    },
                    {
                        query: queries.AdminLandfillQuery,
                        variables: {
                            id: landfill.id,
                        },
                    },
                ],
            })
            .catch(handleApolloError)
    );
};

export const deleteSubarea = async (landfillId: string, id: string) => {
    return handleLoadingPromise(
        client
            .mutate<LandfillSubareaDeleteMutation, LandfillSubareaDeleteMutationVariables>({
                mutation: queries.LandfillSubareaDeleteMutation,
                variables: {
                    landfillId,
                    id,
                },
                refetchQueries: [
                    {
                        query: queries.AdminLandfillQuery,
                        variables: {
                            id: landfillId,
                        },
                    },
                ],
            })
            .catch(handleApolloError)
    );
};

export const handleLandfillGraphqlPermissionError = (error: ApolloError): Promise<never> => {
    return handleEntityGraphqlPermissionError(error, ErrorCodes.LANDFILL_IS_NOT_AVAILABLE);
};

export const subareaMatchChartCategory = (
    subarea: { priceOver50cm: number; priceNotStackable: number },
    category: IChartMaterialCategory
): boolean => {
    return (
        category === ChartMaterialCategory.ALL ||
        (category === ChartMaterialCategory.OVER50 && subarea.priceOver50cm > 0) ||
        (category === ChartMaterialCategory.NON_STACKABLE && subarea.priceNotStackable > 0)
    );
};

export const downloadLandfillReceipts = (landfillId: string, fileName: string, deliveryLineIds: string[]) => {
    return axios
        .post(downloadLandfillReceiptsUrl, { landfillId, deliveryLineIds }, getAxiosConfig({}))
        .then(result => saveToClient(result.data, `${fileName}.zip`))
        .catch(handleAxiosError);
};

export const downloadDeliveryPhotos = (landfillId: string, fileName: string, deliveryLineIds: string[]) => {
    return axios
        .post(landfillEventPhotosUrl, { landfillId, deliveryLineIds }, getAxiosConfig({}))
        .then(result => saveToClient(result.data, `${fileName}.zip`))
        .catch(handleAxiosError);
};

export const downloadLandfillReceiptsXlsx = (landfillId: string, landfillName: string, deliveryLineIds: string[]) => {
    return handleLoadingPromise(
        axios
            .post(downloadLandfillReceiptsXlsxUrl, { landfillId, deliveryLineIds }, getAxiosConfig({}))
            .then(result => saveToClient(result.data, `${landfillName}-${i18n.receipts}.xlsx`))
            .catch(handleAxiosError)
    );
};

export const getLandfillGqlShape = (values: Partial<IDetailedLandfill>): IDetailedLandfill => {
    return {
        __typename: 'Landfill',
        id: '',
        name: '',
        location: homePageMapStore.defaultLocation as LandfillDetailedQuery_landfill_location,
        gateFee: null,
        minMargin: null,
        marginPercent: null,
        receiverIds: [],
        receivers: null,
        customerId: '',
        customer: null,
        workTime: getDefaultWorkTime() as LandfillDetailedQuery_landfill_workTime[],
        comment: null,
        description: null,
        status: null,
        certificates: [],
        isDeleted: false,
        subareas: [],
        ordersInfo: [],
        deliveryLinesInfo: null,
        capacity: 0,
        ...values,
    };
};
