import { ContaminationLevel } from '@common/enums';
import { FilterRange } from '@common/types';
import { computed } from 'mobx';

import {
    getStatusFromPathname,
    getToxicAnalysisFiltersFromApprovalState,
    isLoadStatus,
} from '~/@store/dumpLoads/dumpLoads.helpers';
import { globalSearchStore } from '~/@store/globalSearch';
import { AdminDumpLoadsPagedQueryVariables, DumpLoadsFilter, LoadStatus } from '~/graphql';
import { DumpLoadModeMatching } from '~/utils/dumpLoad';

import { FilterStore, locationStore, PaginationStore, SortStore } from '../common';
import { DEFAULT_DUMP_LOAD_SORT, IDumpLoadStatus, ToxicAnalysisApprovalState } from './dumpLoads.constants';

export type DumpLoadsStoreFilter = {
    projectName?: string | null;
    customerName?: string | null;
    serialNumber?: string | null;
    dumpType?: { name: string; id: string } | null;
    toxicAnalysisApprovalState?: ToxicAnalysisApprovalState | null;
    contractSigned?: boolean | null;
    withTransport?: boolean | null;
    endDate?: FilterRange<Date> | null;
    transportPrice?: FilterRange<number> | null;
    hasToxicAnalysisFiles?: boolean | null;

    FANumber?: string | null;
    comments?: string | null;
    landfillName?: string | null;
    startDate?: FilterRange<Date> | null;
    ownerName?: string | null;
    siteConfirmed?: boolean | null;
    creditCheck?: boolean | null;
    solidTestResult?: ContaminationLevel | null;
    joinCode?: string | null;
    mode?: DumpLoadModeMatching | null;
};

export enum DumpLoadFilterFields {
    projectName = 'projectName',
    customerName = 'customerName',
    serialNumber = 'serialNumber',
    dumpType = 'dumpType',
    toxicAnalysisApprovalState = 'toxicAnalysisApprovalState',
    contractSigned = 'contractSigned',
    hasToxicAnalysisFiles = 'hasToxicAnalysisFiles',

    FANumber = 'FANumber',
    comments = 'comments',
    landfillName = 'landfillName',
    ownerName = 'ownerName',
    siteConfirmed = 'siteConfirmed',
    creditCheck = 'creditCheck',
    solidTestResult = 'solidTestResult',
    joinCode = 'joinCode',
    mode = 'mode',
}

class DumpLoadsStore {
    pagination = new PaginationStore();
    filter = new FilterStore<DumpLoadsStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_DUMP_LOAD_SORT, this.pagination.onClear);

    @computed
    get status(): IDumpLoadStatus {
        return getStatusFromPathname(locationStore.pathname);
    }

    @computed
    get isVolumesMode() {
        return this.status === LoadStatus.IN_PROGRESS || this.status === LoadStatus.DONE;
    }

    @computed
    get dumpLoadsQueryVariables(): AdminDumpLoadsPagedQueryVariables {
        const { endDate, dumpType, toxicAnalysisApprovalState, startDate, mode, ...rest } = this.filter.values;

        let filter: DumpLoadsFilter = rest;
        if (startDate) filter.startDate = startDate.map(m => m?.toISOString() || null);
        if (endDate) filter.endDate = endDate.map(m => m?.toISOString() || null);
        if (dumpType) filter.dumpType = dumpType.id;
        if (toxicAnalysisApprovalState)
            filter = { ...filter, ...getToxicAnalysisFiltersFromApprovalState(toxicAnalysisApprovalState) };
        if (isLoadStatus(this.status)) filter.statuses = [this.status];
        if (mode) {
            switch (mode) {
                case DumpLoadModeMatching.INBOUND_WITH_MATCHING:
                    filter.inbound = true;
                    filter.skipMatching = false;
                    break;
                case DumpLoadModeMatching.INBOUND_WITHOUT_MATCHING:
                    filter.inbound = true;
                    filter.skipMatching = true;
                    break;
                case DumpLoadModeMatching.OUTBOUND_WITH_MATCHING:
                    filter.inbound = false;
                    filter.skipMatching = false;
                    break;
                case DumpLoadModeMatching.OUTBOUND_WITHOUT_MATCHING:
                    filter.inbound = false;
                    filter.skipMatching = true;
                    break;
            }
        }
        filter.searchString = globalSearchStore.search;

        const sort = this.sort.value;

        const connection = this.pagination.connection;

        return { filter, sort, connection };
    }
}

export const dumpLoadsStore = new DumpLoadsStore();
