import { ApolloError } from '@apollo/client';
import { ErrorCodes, ProjectStatus } from '@common/enums';
import { canCalculateDestination } from '@common/functions';
import { startPages } from '@common/routes';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import QRCode, { QRCodeToDataURLOptions } from 'qrcode';

import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import * as projectQueries from '~/@store/projects/projects.queries';
import { ProjectQuery, ProjectUpdateMutation } from '~/@store/projects/projects.queries';
import { appStoreLink, googlePlayLink, MAX_PAYLOAD_SIZE } from '~/config/constants';
import type { IAbilityContext } from '~/contexts';
import { handleLoadingPromise } from '~/services/loader';
import { globalMessage } from '~/services/message';

import client, { downloadProjectReceiptsUrl, downloadProjectReceiptsXlsxUrl } from '../apolloClient';
import browserHistory from '../browserHistory';
import {
    calculateDestinationResult as ICalculateDestinationResult,
    calculateDestinationResult_calculateDestinationResult as IResults,
    calculateDestinationResultVariables as ICalculateDestinationResultVariables,
    DumpLoadDeliveryInfoInput,
    ProjectAddCertificateMutation,
    ProjectAddCertificateMutationVariables,
    ProjectQuery_project_dumpLoads,
    ProjectRemoveCertificateMutation,
    ProjectRemoveCertificateMutationVariables,
    ProjectUpdate,
    ProjectUpdateMutation as ProjectUpdateMutation_gql,
    ProjectUpdateMutation_projectUpdate,
    ProjectUpdateMutationVariables,
    SubstanceItemInput,
} from '../graphql';
import i18n from '../i18n';
import { hasAdminPermissions } from './auth';
import { getAxiosConfig, handleAxiosError } from './axios';
import { saveToClient } from './files';
import { handleApolloError, handleEntityGraphqlPermissionError, isApolloError } from './index';

export const downloadProjectReceipts = (projectId: string, fileName: string, deliveryLineIds: string[]) => {
    return axios
        .post(downloadProjectReceiptsUrl, { projectId, deliveryLineIds }, getAxiosConfig({}))
        .then(result => saveToClient(result.data, `${fileName}.zip`))
        .catch(handleAxiosError);
};

export const downloadProjectReceiptsXlsx = (projectId: string, projectName: string, deliveryLineIds: string[]) => {
    return handleLoadingPromise(
        axios
            .post(downloadProjectReceiptsXlsxUrl, { projectId, deliveryLineIds }, getAxiosConfig({}))
            .then(result => saveToClient(result.data, `${projectName}-${i18n.receipts}.xlsx`))
            .catch(handleAxiosError)
    );
};

export const handleProjectPermissionError = (context: IAbilityContext, error: unknown) => {
    if (!isApolloError(error)) return Promise.reject(error);

    // @ts-ignore code does not exists in GraphQLError, but it is used here
    if (!hasAdminPermissions(context) && error.graphQLErrors?.[0]?.code === ErrorCodes.PERMISSION_ERROR) {
        browserHistory.push(startPages.homepage);

        globalMessage.error(i18n.errorCodes.PROJECT_IS_NOT_AVAILABLE);

        return Promise.reject(error);
    }

    return handleApolloError(error);
};

export const handleProjectGraphqlPermissionError = (
    error: ApolloError,
    refetch?: (...args: unknown[]) => Promise<unknown>
): Promise<never> => {
    return handleEntityGraphqlPermissionError(error, ErrorCodes.PROJECT_IS_NOT_AVAILABLE, refetch);
};

export function calculateCost(
    context: IAbilityContext,
    projectId: string,
    input: DumpLoadDeliveryInfoInput,
    substances: SubstanceItemInput[]
): Promise<IResults> {
    return handleLoadingPromise(
        client.query<ICalculateDestinationResult, ICalculateDestinationResultVariables>({
            query: queries.calculateDestinationResultQuery,
            variables: { projectId, input, substances },
            fetchPolicy: 'network-only',
        })
    )
        .catch(e => handleProjectPermissionError(context, e))
        .then(({ data: { calculateDestinationResult } }) => {
            if (!calculateDestinationResult) return Promise.reject(new Error('Empty response'));

            return calculateDestinationResult;
        });
}

export const canCalculateDestinationForDumpLoad = ({
    dumpType,
    status,
    amount,
    unitOfMeasure,
}: ProjectQuery_project_dumpLoads) => canCalculateDestination(dumpType?.id, status, amount, unitOfMeasure);

export const saveNotes = async (comment: string, id: string, ver: number) => {
    const input: ProjectUpdate = {
        comment,
        id,
        ver,
    };

    await client.mutate<ProjectUpdateMutation_gql, ProjectUpdateMutationVariables>({
        mutation: projectQueries.ProjectUpdateMutation,
        variables: { input },
    });
};

export const uploadProjectCertificate = (file: File, projectId: string, refetch?: () => Promise<unknown>): void => {
    if (file.size < MAX_PAYLOAD_SIZE) {
        const ignoredPromise = handleLoadingPromise(
            client
                .mutate<ProjectAddCertificateMutation, ProjectAddCertificateMutationVariables>({
                    mutation: projectQueries.ProjectAddCertificateMutation,
                    variables: { id: projectId, certificate: file },
                })
                .then(() => {
                    globalMessage.success(i18n.fileUploaded);
                })
                .catch(handleApolloError)
                .then(() => refetch?.())
        );
    } else {
        globalMessage.error(i18n.tooLargeFile);
    }
};

export const deleteProjectCertificate = (
    fileName: string,
    projectId: string,
    refetch?: () => Promise<unknown>
): void => {
    const ignoredPromise = handleLoadingPromise(
        client
            .mutate<ProjectRemoveCertificateMutation, ProjectRemoveCertificateMutationVariables>({
                mutation: projectQueries.ProjectRemoveCertificateMutation,
                variables: { id: projectId, fileName },
            })
            .catch(handleApolloError)
            .then(() => refetch?.())
    );
};

export const updateProject = (
    variables: ProjectUpdateMutationVariables,
    context: IAbilityContext
): Promise<ProjectUpdateMutation_projectUpdate> =>
    handleLoadingPromise(
        client.mutate<ProjectUpdateMutation_gql>({
            mutation: ProjectUpdateMutation,
            variables,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ProjectQuery,
                    variables: { id: variables.input.id },
                },
            ],
        })
    )
        .then(resp => {
            const project = resp.data?.projectUpdate;
            if (!project) return Promise.reject();

            return project;
        })
        .catch(error => handleProjectPermissionError(context, error));

export const getJoinCode = (
    project?: {
        status: ProjectStatus;
        joinCode: string | null;
    } | null
) => {
    return project?.status === ProjectStatus.IN_PROGRESS ? project.joinCode : null;
};

const SIZE = 50;
const FONT_HEIGHT = 5;
const PADDING = 15;
const A4_HEIGHT = 297;
const A4_WIDTH = 210;

export const processJoinCodePDf = async (pdf: jsPDF) => {
    const qrCodeOptions: QRCodeToDataURLOptions = { margin: 0 };

    pdf.setFontSize(12);

    let qrCode = await QRCode.toDataURL(googlePlayLink, qrCodeOptions);
    pdf.addImage(qrCode, 'JPEG', PADDING, A4_HEIGHT - PADDING - SIZE, SIZE, SIZE);
    pdf.text(i18n.googlePlay, PADDING, A4_HEIGHT - PADDING - SIZE - FONT_HEIGHT);

    qrCode = await QRCode.toDataURL(appStoreLink, qrCodeOptions);
    pdf.addImage(qrCode, 'JPEG', A4_WIDTH - PADDING - SIZE, A4_HEIGHT - PADDING - SIZE, SIZE, SIZE);
    pdf.text(i18n.appStore, A4_WIDTH - PADDING - SIZE, A4_HEIGHT - PADDING - SIZE - FONT_HEIGHT);

    return pdf;
};
